import { ISODate, ISODateTime } from './date'
import { SheLocation } from './location'

import { SessionType } from './sessions'
import { TokenType } from './tokens'
import { MembershipPlanGroupKey } from './membershipPlans'

export type TrialLessonId = number
export type TrialLessonArea = 'tokyo' | 'nagoya' | 'osaka'
export enum TrialLessonTarget {
  NORMAL = 'normal',
  EXPERT = 'expert',
  NAGOYA = 'nagoya',
}

export enum TrialLessonStatus {
  ACTIVE = 'active',
  HIDDEN = 'hidden',
  FULL = 'full',
}

export type TrialLessonIndexView = {
  id: TrialLessonId
  title: string
  lesson1: string
  lesson2?: string
  capacity: number
  location?: SheLocation
  area: TrialLessonArea
  active_reservation_count: number
  status: TrialLessonStatus
  starts_at: ISODateTime
  ends_at: ISODateTime
  created_at: ISODateTime
  target: TrialLessonTarget
  session_type?: SessionType
  required_token_type?: TokenType
  membership_plan_group_keys: MembershipPlanGroupKey[]
  zoom_url?: string
}
export type CalendarLessonStatus = 'reservable' | 'none' | 'full' | 'reserved'

export type CalendarLessonType = {
  id: number
  status: CalendarLessonStatus
}

export type Slot = {
  [key: string]: CalendarLessonType
}

export type LessonData = {
  [key: ISODate]: {
    is_holiday: boolean
    slots: Slot
  }
}

export type ContentType = 'onlyOtl' | 'recommendOns' | 'normal'

export function isOnline(lesson: TrialLessonIndexView) {
  return lesson.location == null
}

export function shouldShowSecondLesson(lesson: TrialLessonIndexView) {
  return !!lesson.lesson2
}
