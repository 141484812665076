export const fetchLessons = async (
  url: string,
  {
    arg,
  }: {
    arg: {
      target?: 'expert'
      lesson_type_name?: string
      start_date: string
      end_date: string
    }
  }
) =>
  fetch(`${url}?${new URLSearchParams(arg).toString()}`, {
    method: 'GET',
  }).then(res => res.json())
