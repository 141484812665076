import React from 'react'

import styles from './index.module.scss'
import ChevronLeft from '../../public/svgs/chevron_left.svg'
import ChevronRight from '../../public/svgs/chevron_right.svg'

type Prop = {
  getNextWeekLessonData: () => void
  getPreviousWeekLessonData: () => void
  setWeek: (week: number) => void
  week: number
}
export const ChangeWeek = ({
  getNextWeekLessonData,
  getPreviousWeekLessonData,
  setWeek,
  week,
}: Prop) => {
  return (
    <div className={styles.changeWeek}>
      <button
        className={styles.previous}
        type="button"
        disabled={week === 0}
        onClick={() => {
          setWeek(week - 1)
          getPreviousWeekLessonData()
        }}
      >
        <img
          className={week === 0 ? styles.disabled : ''}
          src={ChevronLeft.src}
        />
        前の一週間
      </button>
      <button
        className={styles.next}
        type="button"
        disabled={week >= 1}
        onClick={() => {
          setWeek(week + 1)
          getNextWeekLessonData()
        }}
      >
        次の一週間
        <img
          className={week >= 1 ? styles.disabled : ''}
          src={ChevronRight.src}
        />
      </button>
    </div>
  )
}
