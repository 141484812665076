export const timestampToTime = (timestamp: number) => {
  const date = new Date(timestamp)
  const yyyy = `${date.getFullYear()}`
  const MM = `${date.getMonth() + 1}`.padStart(2, '0')
  const dd = `${date.getDate()}`.padStart(2, '0')

  return `${yyyy}-${MM}-${dd}`
}

export const FOR_ONE_DAY = 1000 * 60 * 60 * 24
export const FOR_ONE_WEEK = 1000 * 60 * 60 * 24 * 6
