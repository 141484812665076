import * as React from 'react'
import classNames from 'classnames'
import styles from './index.module.scss'
import { Loader } from '../Form/Loader'

export const ReservationButton = ({
  id,
  loading = false,
  disabled = false,
  children,
  onClick,
}: {
  id?: string
  loading?: boolean
  disabled: boolean
  children: React.ReactNode
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}) => (
  <button
    type="submit"
    id={id}
    className={classNames(styles.reservationButton, {
      [styles.disabled]: disabled,
    })}
    disabled={disabled}
    onClick={!loading && !disabled ? onClick : () => ({})}
  >
    {loading ? <Loader /> : children}
  </button>
)
