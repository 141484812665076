import axios from 'axios'

const reserveTrialLesson = (
  token: string,
  lessonId: string,
  body: any,
  isSignedIn = false
) => {
  // merge other necessary object
  if (!isSignedIn) {
    body.furigana = ''
    body.age = null
    body.job_company = ''
    body.job_department = ''
    body.job_title = ''
    body.facebook_url = ''
    body.via = ''
  }
  return axios
    .post(
      `${process.env.NEXT_PUBLIC_API_URL}/api/commons/v1/trial_lessons/${lessonId}/event_reservations?`,
      {
        user: Object.assign(
          body,
          // if name is existed trim half and full empty space
          body.name
            ? {
                name: body.name.replace(/[\s\u{3000}]/gu, ''),
              }
            : {}
        ),
        payment_type: 'credit_card',
        validation_type: 'succinct_trial',
      },
      { headers: { Authorization: `Bearer ${token}` } }
    )
    .then(response => {
      return response.data
    })
    .catch(error => {
      window.alert(`予約に失敗しました:${error.body}`)
    })
}

export default reserveTrialLesson
