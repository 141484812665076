import { RefObject, useEffect, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

export type ElementMetrics = {
  width: number
  offsetWidth: number
  height: number
  offsetHeight: number
  aspectRatio: number
}

export const useElementMetrics = (
  ref: HTMLElement | RefObject<HTMLElement> | null
): ElementMetrics | null => {
  const [metrics, setMetrics] = useState<ElementMetrics | null>(null)
  useEffect(() => {
    if (!ref) {
      return
    }
    const element = 'current' in ref ? ref.current : ref
    if (element) {
      const observer = new ResizeObserver(([entry]) => {
        const { width, height } = entry.contentRect
        const { offsetWidth, offsetHeight } = entry.target as HTMLDivElement
        setMetrics({
          width,
          height,
          offsetWidth,
          offsetHeight,
          aspectRatio: width / height,
        })
      })
      observer.observe(element)
      return () => observer.unobserve(element)
    }
  }, [ref])
  return metrics
}
