import * as React from 'react'
import styles from './index.module.scss'
import classNames from 'classnames'

const ActionButton = ({
  type,
  onClick,
  children,
}: {
  type: string
  onClick?: () => void
  children: React.ReactNode | string
}) => (
  <button
    type="button"
    className={classNames(styles.actionButton, styles[type])}
    onClick={type === 'disabled' ? undefined : onClick}
  >
    {children}
  </button>
)

export const UpdateActionButton = ({ onClick }: { onClick?: () => void }) => (
  <ActionButton type="normal" onClick={onClick}>
    変更する
  </ActionButton>
)
