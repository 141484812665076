import React from 'react'

import styles from './index.module.scss'

import Circle from '../../public/svgs/circle.svg'

import { CalendarLessonStatus } from '../../types/trialLessons'
export const Status = ({ status }: { status: CalendarLessonStatus }) => {
  switch (status) {
    case 'reservable':
      return <img className={styles.reservable} src={Circle.src} />
    case 'none':
      return <span className={styles.none}>-</span>
    case 'full':
      return <span className={styles.gray}>満席</span>
    case 'reserved':
      return <span className={styles.gray}>予約済</span>
  }
}
