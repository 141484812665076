import React, { RefObject, useState } from 'react'
import { useElementMetrics } from '../../hooks/useElementMetrics'
import { animated, useSpring } from 'react-spring'
import styles from './index.module.scss'

export const ReservationStepHeader = ({
  id,
  stepText,
  // className = undefined,
  // styleName = undefined,
  children,
}: {
  id: string
  stepText?: string
  children: React.ReactNode
}) => (
  <div className={styles.reservationStepHeader} id={id}>
    {stepText && <div className={styles.stepText}>{stepText}</div>}
    <div className={styles.headerBody}>{children}</div>
  </div>
)

export const ReservationStepBody = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [ref, setRef] = useState<HTMLElement | RefObject<HTMLElement> | null>(
    null
  )
  const metrics = useElementMetrics(ref)
  const styleProps = useSpring({
    maxHeight: metrics ? metrics.offsetHeight : 0,
  })
  return (
    <animated.div className={styles.reservationStepBody} style={styleProps}>
      <div className={styles.reservationStepBodyInner} ref={setRef}>
        {children}
      </div>
    </animated.div>
  )
}

export const ReservationStepSubHeader = ({
  children,
}: {
  children: React.ReactNode
}) => <div className={styles.reservationStepSubHeader}>{children}</div>
