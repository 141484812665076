import React, { ChangeEvent, useRef, useState, useEffect } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames'
import { ArrowDropDown } from '@material-ui/icons'

function BirthdayInputField({
  className,
  id,
  name,
  label,
  defaultValue,
  onChange,
  onBlur,
  error,
}: {
  className: string
  id: string
  name: string
  label: string
  defaultValue: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur: (e: ChangeEvent<HTMLSelectElement>) => void
  error: string
}) {
  const birthdayInputRef = useRef<HTMLInputElement>(null)
  const birthYearRef = useRef<HTMLSelectElement>(null)
  const birthMonthRef = useRef<HTMLSelectElement>(null)
  const birthDayRef = useRef<HTMLSelectElement>(null)

  const [birthYearMonthDate, setBirthYearMonthDate] =
    useState<string>(defaultValue)

  const [birthYear, setBirthYear] = useState<string>(
    String(new Date(defaultValue).getFullYear())
  )
  const [birthMonth, setBirthMonth] = useState<string>(
    String(new Date(defaultValue).getMonth() + 1)
  )
  const [birthDay, setBirthDay] = useState<string>(
    String(new Date(defaultValue).getDate())
  )

  const selectBirthYear = (e: ChangeEvent<HTMLSelectElement>) => {
    setBirthYear(e.target.value)
    const birthday = formatDate(e.target.value, birthMonth, birthDay)
    setBirthYearMonthDate(birthday)
  }
  const selectBirthMonth = (e: ChangeEvent<HTMLSelectElement>) => {
    setBirthMonth(e.target.value)
    const birthday = formatDate(birthYear, e.target.value, birthDay)
    setBirthYearMonthDate(birthday)
  }
  const selectBirthDay = (e: ChangeEvent<HTMLSelectElement>) => {
    setBirthDay(e.target.value)
    const birthday = formatDate(birthYear, birthMonth, e.target.value)
    setBirthYearMonthDate(birthday)
  }

  // 生年月日入力欄生成
  const setYear = () => {
    // 年を生成
    for (let i = 1920; i <= new Date().getFullYear(); i++) {
      const option = document.createElement('option')
      option.value = String(i)
      option.text = String(i)
      if (birthYearRef.current) birthYearRef.current.appendChild(option)
    }
    if (birthYearRef.current) birthYearRef.current.value = birthYear
  }
  const setMonth = () => {
    // 月を生成
    for (let i = 1; i <= 12; i++) {
      const option = document.createElement('option')
      option.value = String(i)
      option.text = String(i)
      if (birthMonthRef.current) birthMonthRef.current.appendChild(option)
    }
    if (birthMonthRef.current) birthMonthRef.current.value = birthMonth
  }
  const setDay = () => {
    // 月に対して存在する日付にするために一度空にする
    let children: HTMLCollection | null = null
    if (birthDayRef.current) children = birthDayRef.current.children
    while (children?.length) {
      children[0].remove()
    }
    // 日を生成
    if (
      birthYearRef.current &&
      birthYearRef.current.value !== '' &&
      birthMonthRef.current &&
      birthMonthRef.current.value !== ''
    ) {
      const lastday = new Date(
        Number(birthYearRef.current.value),
        Number(birthMonthRef.current.value),
        0
      ).getDate()
      for (let i = 1; i <= lastday; i++) {
        const option = document.createElement('option')
        option.value = String(i)
        option.text = String(i)
        if (birthDayRef.current) birthDayRef.current.appendChild(option)
      }
      if (birthDayRef.current) birthDayRef.current.value = birthDay
    }
  }

  const formatDate = (year: string, month: string, day: string) => {
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
  }

  useEffect(() => {
    setYear()
    setMonth()
    setDay()
  }, [])

  useEffect(() => {
    setDay()
  }, [birthYear, birthMonth])

  // 誕生日入力欄の値が変更されたらonChangeイベントを実行
  useEffect(() => {
    onChange({
      target: birthdayInputRef.current,
    } as ChangeEvent<HTMLInputElement>)
  }, [birthYearMonthDate])

  return (
    <div className={styles.formGroup}>
      <label htmlFor={id} className={styles.formLabel}>
        {label}
      </label>
      <input
        hidden
        value={birthYearMonthDate}
        id={id}
        name={name}
        ref={birthdayInputRef}
      />
      <div className={styles.birthdayForm}>
        <div
          className={classNames(
            styles.formGroupBody,
            styles.birthdayFormInputWrapper,
            styles.birthdayFormYear
          )}
        >
          <div
            className={classNames(
              styles.selectWrapper,
              styles.birthdayFormInput
            )}
          >
            <select
              id="birth_year"
              name="birth_year"
              className={className}
              ref={birthYearRef}
              value={birthYear}
              onChange={selectBirthYear}
              onBlur={onBlur}
            ></select>
            <div className={styles.icon}>
              <ArrowDropDown />
            </div>
          </div>
          <div>年</div>
        </div>
        <div
          className={classNames(
            styles.formGroupBody,
            styles.birthdayFormInputWrapper,
            styles.birthdayFormMonth
          )}
        >
          <div
            className={classNames(
              styles.selectWrapper,
              styles.birthdayFormInput
            )}
          >
            <select
              id="birth_month"
              name="birth_month"
              className={className}
              ref={birthMonthRef}
              value={birthMonth}
              onChange={selectBirthMonth}
              onBlur={onBlur}
            ></select>
            <div className={styles.icon}>
              <ArrowDropDown />
            </div>
          </div>
          <div>月</div>
        </div>
        <div
          className={classNames(
            styles.formGroupBody,
            styles.birthdayFormInputWrapper,
            styles.birthdayFormDay
          )}
        >
          <div
            className={classNames(
              styles.selectWrapper,
              styles.birthdayFormInput
            )}
          >
            <select
              id="birth_day"
              name="birth_day"
              className={className}
              ref={birthDayRef}
              value={birthDay}
              onChange={selectBirthDay}
              onBlur={onBlur}
            ></select>
            <div className={styles.icon}>
              <ArrowDropDown />
            </div>
          </div>
          <div>日</div>
        </div>
      </div>
      {error && (
        <span id={`${id}_error`} className={styles.formError}>
          {error}
        </span>
      )}
    </div>
  )
}

export default BirthdayInputField
