import React, { ChangeEvent, HTMLInputTypeAttribute } from 'react'
import styles from './index.module.scss'

function InputField({
  className,
  id,
  type = 'text',
  name,
  placeholder,
  label,
  defaultValue,
  onChange,
  onBlur,
  error,
}: {
  className: string
  id: string
  type?: HTMLInputTypeAttribute
  name: string
  placeholder: string
  label: string
  defaultValue: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur: (e: ChangeEvent<HTMLInputElement>) => void
  error: string
}) {
  return (
    <div className={styles.formGroup}>
      <label htmlFor={id} className={styles.formLabel}>
        {label}
      </label>
      <div className={styles.formGroupBody}>
        <input
          required
          className={className}
          type={type}
          id={id}
          name={name}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={onChange}
          onBlur={onBlur}
          aria-describedby={`${id}_error`}
        />
        {error && (
          <span id={`${id}_error`} className={styles.formError}>
            {error}
          </span>
        )}
      </div>
    </div>
  )
}

export default InputField
