import React, { ChangeEvent } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames'
import { ArrowDropDown } from '@material-ui/icons'

export type SelectItem = {
  label: string
  value: string
  metaAttributes?: object
}

function SelectInputField({
  className,
  id,
  name,
  placeholder,
  label,
  defaultValue,
  items,
  onChange,
  onBlur,
  error,
}: {
  className: string
  id: string
  name: string
  placeholder: string
  label: string
  defaultValue: string
  items: SelectItem[]
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void
  onBlur: (e: ChangeEvent<HTMLSelectElement>) => void
  error: string
}) {
  return (
    <div className={styles.formGroup}>
      <label htmlFor={id} className={styles.formLabel}>
        {label}
      </label>
      <div className={classNames(styles.formGroupBody, styles.selectWrapper)}>
        <select
          id={id}
          name={name}
          className={className}
          onChange={onChange}
          onBlur={onBlur}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {items.map(item => {
            return (
              <option
                key={item.value}
                value={item.value}
                {...(item.metaAttributes || {})}
                selected={defaultValue === item.value}
              >
                {item.label}
              </option>
            )
          })}
        </select>
        <div className={styles.icon}>
          <ArrowDropDown />
        </div>
      </div>
      {error && (
        <span id={`${id}_error`} className={styles.formError}>
          {error}
        </span>
      )}
    </div>
  )
}

export default SelectInputField
