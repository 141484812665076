import axios from 'axios'

const cancelTrialLesson = (token: string, reserveId: string) => {
  axios.put(
    `${process.env.NEXT_PUBLIC_API_URL}/api/commons/v1/users/current/event_reservations/${reserveId}`,
    {
      status: 'cancelled',
    },
    { headers: { Authorization: `Bearer ${token}` } }
  )
}

export default cancelTrialLesson
