import { TrialLessonId } from './trialLessons'
export enum LocationKey {
  SHE_AOYAMA = 'she_aoyama',
  SHE_GINZA = 'she_ginza',
  SHE_NAGOYA = 'she_nagoya',
  SHE_UMEDA = 'she_umeda',
}

export enum LocationType {
  OFFICIAL_BASE = 'official_base',
  TEMPORARY = 'temporary',
}

export type OnlineLocation = null

export type SheLocation = {
  id: TrialLessonId
  key: LocationKey | string
  name: string
  short_name: string
  location_type: LocationType
  zipcode: string
  address: string
  access_summary?: string
  short_access_summary?: string
  access_description?: string
  google_map_url?: string
  image?: string
}

export type LocationId = number | null

export function getLocationName(location?: SheLocation | OnlineLocation) {
  if (!location) {
    return 'オンライン'
  } else {
    return location.name
  }
}
