import * as React from 'react'
import styles from './index.module.scss'

const LocationBadgeBase = ({ children }: { children: React.ReactNode }) => (
  <div className={styles.badgeWrapper}>
    <div className={styles.locationBadge}>{children}</div>
  </div>
)

export const LocationBadge3 = ({
  location,
}: {
  location?: { short_name: string; name: string }
}) => {
  return (
    <LocationBadgeBase>
      {location?.short_name || location?.name}
    </LocationBadgeBase>
  )
}
